<template>
  <div
    v-if="isLoaded"
    class="blog-wrapper"
  >
    <!-- action -->
    <div
      v-if="isBrand && isEntityAdmin"
      class="d-flex align-items-end justify-content-end"
    >
      <b-button
        id="intro_add_collection_button"
        :to="{ name: 'collection/edit'}"
        class="font-weight-bold font-size-16-px flex-wrap elements-center add-collection-btn"
        variant="custom-primary"
        @click="addCollection"
      >
        <feather-icon
          icon="PlusIcon"
          size="20"
          class="mr-25"
        />
        Add Collection
      </b-button>
    </div>
    <!-- Live collection list -->
    <template v-if="brandCollection.length">
      <h2 class="color-dark-black mb-10-px">
        {{ currentAssociationEntityName || profile && profile.entityName }}'s Collections
      </h2>
      <draggable
        v-if="brandCollection.length"
        tag="section"
        class="brand-collections blog-list-wrapper ecommerce-application mt-0"
        :list="brandCollection"
        :sort="isBrand"
        @change="onDragged"
      >
        <collection-card
          v-for="collection in brandCollection"
          :key="collection._id"
          :collection="collection"
          :fetch-collections="fetchCollections"
        />
      </draggable>
    </template>
    <!-- previous collection -->
    <template v-if="previousCollection.length">
      <h2 class="color-dark-black mb-10-px">
        Previous Collections
      </h2>
      <section
        v-if="previousCollection.length"
        class="brand-collections blog-list-wrapper ecommerce-application mt-0"
      >
        <collection-card
          v-for="collection in previousCollection"
          :key="collection._id"
          :collection="collection"
          is-previous-collection
          :fetch-collections="fetchCollections"
        />
      </section>
    </template>
    <div
      v-if="!brandCollection.length && !previousCollection.length"
      class="misc-inner p-2 p-sm-3 m-auto"
    >
      <div class="w-100 text-center">
        <b-container fluid>
          <h2 class="mb-1">
            No Collections Found
          </h2>
          <p class="mb-2">
            You don't have any uploaded collections
          </p>
        </b-container>
        <!-- image -->
        <b-img
          fluid
          :src="notFoundImg"
          alt="Error page"
        />
      </div>
    </div>
    <tool-kit :toolkit-page="TOOLKIT_PAGE.COLLECTIONS" />
  </div>
</template>

<script>
import {
  BContainer, BImg, BButton, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { apiToastError, apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import analytics from '@/@core/utils/analytics'
import constants, { TOOLKIT_PAGE } from '@/constants'
import draggable from 'vuedraggable'
import { GET_COLLECTION_LIST, UPDATE_COLLECTION } from '@/store/modules/collection.module'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import UserRoleMixin from '../UserRoleMixin.vue'
import CollectionCard from '../brand/detail-v2/CollectionCard.vue'

const { COLLECTION_STATUS } = c

export default {
  name: 'CollectionList',
  components: {
    BContainer,
    BImg,
    BButton,
    draggable,
    CollectionCard,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  mixins: [UserRoleMixin],
  data() {
    return {
      isLoaded: false,
      notFoundImg: require('@/assets/images/pages/error.svg'),
      TOOLKIT_PAGE,
      brandCollection: [],
      previousCollection: [],
    }
  },
  computed: {
    profile() {
      return this.$store.getters.profile
    },
    currentAssociationEntityName() {
      return this.$store.getters.getCurrentAssociation?.entity?.entityName
    },
  },
  beforeMount() {
    this.fetchCollections()
  },
  methods: {
    onDragged(data) {
      const draggedCollection = data.moved
      const draggedCollectionId = draggedCollection.element._id
      const newIndex = draggedCollection.newIndex
      this.$store
        .dispatch(UPDATE_COLLECTION, {
          collectionId: draggedCollectionId,
          payload: { orderIndex: newIndex },
        })
        .then(() => {
          apiToastSuccess('Dragged successfully')
        })
        .catch(() => {
          apiToastError('Dragging failed')
        })
    },
    addCollection() {
      analytics.track(constants.TRACKS.ACTIONS.BRAND_STARTS_ADDING_COLLECTION)
    },
    async fetchCollections() {
      await this.$store.dispatch(GET_COLLECTION_LIST)
        .then(res => {
          const collections = res.data.data.collections
          this.brandCollection = collections.filter(col => col.status !== COLLECTION_STATUS.EXPIRED.VALUE)
          this.previousCollection = collections.filter(col => col.status === COLLECTION_STATUS.EXPIRED.VALUE)
          this.isLoaded = true
        })
        .catch(err => {
          apiToastWarning(err)
        })
      analytics.track(constants.TRACKS.PAGES.BRAND_COLLECTIONS)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
.add-collection-btn {
  height: 46px;
}
</style>
